import React from "react";
import { PageProps, graphql } from "gatsby";
import Header from "@/components/Header";
import Container from "@/components/Container";
import ListNote from "@/components/ListNote";
import {
  HERO_TITLE,
  HERO_SUBTITLE,
  SITE_TITLE,
  SITE_DESCRIPTION,
} from "../../config";
import Layout from "@/components/Layout";

const IndexPage = ({ data, pageContext }) => {
  const { edges } = data.allMdx;

  return (
    <Layout>
      <div className="my-10">
        <span className="flex w-44 px-3 py-1 text-black text-lg font-serif tracking-wide leading-none bg-blue-100 rounded-full">
          Hello there <span className=" ml-4 animate-waving-hand">👋🏻</span>
        </span>
        <h1 className="text-black text-5xl mt-4 leading-normal transition-all ease-in-out">
          <span className="border-0" style={{ width: "max-content" }} contentEditable="true" spellCheck="false">I'm Alex Reis!</span>
        </h1>
        <h1 className="text-black text-3xl mt-4 md:text-3xl leading-normal md:leading-snug transition-all ease-in-out">
          {HERO_TITLE}
        </h1>
      </div>  
      <div className="relative grid grid-cols-12 gap-6">
        <ListNote edges={edges} />
      </div>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexTemplateQuery {
    allMdx(
      filter: {
        frontmatter: { publish: { ne: false } }
        fileAbsolutePath: { regex: "/vault/" }
      }
      limit: 10
      sort: { order: DESC, fields: fields___date }
    ) {
      edges {
        node {
          ...postList
        }
      }
    }
  }
`;
